import React from 'react'
import Footer from "./footer"
import FuncNavbar from "./func_navbar"
import { useStaticQuery, graphql } from "gatsby"




const Layout = ({children}) => {
 
    const data = useStaticQuery(graphql`
    {
        swapi {
          sociallinks {
            icon
            link
            name
            id
          }
        }
      }
    `)
    
    const {swapi:{sociallinks}}=data

    return (
        <>  
            
            <FuncNavbar socialLinks={sociallinks}/>
            <main>
                {children}
            </main>
            <Footer socialLinks={sociallinks}/>
            
        </>
    )
}

export default Layout
