import React from "react"
import { Helmet } from "react-helmet"

const HeaderElemets = props => {
  return (
    <Helmet>
      <title>{props.headAtributes.seoTitle}</title>
      <meta name="description" content={props.headAtributes.seoDescription}></meta>
      <meta name="google-site-verification" content="NvXhDfc3Xo-uI1BHlZFsxJp0b2dtNS120f5-PWmDCqo" />


      <meta property="og:title" content={props.headAtributes.seoTitle} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={"https://izarada.com"+ props.headAtributes.url} />
      <meta name="og:description" content={props.headAtributes.seoDescription} />

      <meta property="og:image" content={props.headAtributes.image} />
      <meta name="ahrefs-site-verification" content="c533cd953f294a998f2b7ba1f7170c189f6354c4b6d276abe70c7de2d2bc8de4" />


    </Helmet>
  )
}

export default HeaderElemets
