import React, { useState } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import logoWhite from '../images/logo_white.png'


const FunctionNavbar = props => {
  // const {data} = useStaticQuery(getData)
  // const {site:{info:{title,person:{name,age}}}} = useStaticQuery(getData)

  const [showComponent, setShowComponent] = useState(false)

  const handleClick = () => {
    setShowComponent(!showComponent)
  }

  const data = useStaticQuery(graphql`
    {
      swapi {
        menuitems {
          linkText
          linkPage {
            id
            url
            title
          }
        }
      }
    }
  `)

  // console.log(data)
  const {
    swapi: { menuitems },
  } = data
  // console.log(menuitems)
  //   const {swapy:{menuitems}} = data
  //   console.log(menuitems)

  return (
    <nav
      className={`${
        showComponent
          ? "transition duration-500 ease-in-out transform h-screen bg-primary"
          : ""
      }`}
    >
      <div
        className={`px-4 py-4 flex content-center	border-b-2 border-solid ${
          showComponent ? "border-white" : "border-red-500"
        }`}
      >
        {/* <div className="flex-2">search</div> */}
        <div className="flex-1 text-left lg:text-center font-bold">
          <Link to="/">
            <img className="w-24 inline-block" src={logoWhite} alt="izarada.com logo"></img>
          </Link>
        </div>
        <div className=" flex hidden lg:flex flex-2  justify-center items-center">
          <ul>{
          menuitems.map(element=>{
              return(
                <li className="inline-block p-2 text-xl">
                  <span className={`font-bold  mr-3 ${showComponent?"text-white":"text-primary"}`}>/</span><Link className={`no-underline uppercase ${showComponent?"text-white":"text-gray-500"}`} to={element.linkPage.url}>{element.linkText}</Link> 
                </li>
              )
            
            })
            }
            </ul>
        </div>
        <div className="flex-1 text-right lg:text-center">
          <div
            className="cursor-pointer inline-block content-end pt-5"
            onClick={handleClick}
          >
            <div
              className={`bar1 w-10 h-1.5  my-1 transition duration-500 ${
                showComponent
                  ? "bg-white transform -rotate-45 translate-y-2"
                  : "bg-primary"
              }`}
            ></div>
            <div
              className={`bar1 w-10 h-1.5 bg-primary my-1 opacity-0  transition-opacity duration-700 ${
                showComponent ? "ease-out bg-white" : " ease-in opacity-100"
              }`}
            ></div>
            <div
              className={`bar1 w-10 h-1.5 my-1 transition duration-1000 ${
                showComponent
                  ? "bg-white transform rotate-45 -translate-y-2.5"
                  : "bg-primary"
              }`}
            ></div>
          </div>
        </div>
      </div>

      <div
        className={`mt-10 md:mt-20 text-center text-2xl font-extrabold text-white text-bold ${
          showComponent ? "" : "hidden"
        }`}
      >
        <ul>
          {menuitems.map(element => {
            return (
              <li className="p-4">
                <Link
                  to={element.linkPage.url}
                  className="text-white border-b-2 border-solid border-red-300 py-2 no-underline"
                >
                  {element.linkText}
                </Link>
              </li>
            )
          })}
          <li className="p-4">
            {props.socialLinks.map(element => {
              return (
                <>
                  <a href={element.link} className="h-4">
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="h-10 w-10 fill-current text-white inline-block px-1"
                    >
                      <title>{element.name} icon</title>
                      <path d={element.icon} />
                    </svg>
                  </a>
                </>
              )
            })}
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default FunctionNavbar
