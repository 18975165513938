import React from "react"
import logoWhite from '../images/logo_white.png'
import { Link } from "gatsby"


const footer = props => {
  
  return (
    <div className="h-80 bg-gray-100  py-10 border-t-2 border-solid border-primary">
      
        <div className="text-center ">
            


            
          {props.socialLinks.map(element => {
            return (
              <>
                <a href={element.link} className="h-4" target="_blank">
                  <svg
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-10 w-10 fill-current text-red-500 inline-block px-1"
                  >
                    <title>{element.name} icon</title>

                    <path d={element.icon} />
                  </svg>
                </a>
              </>
            )
          })}
          <div className="flex-1 text-center lg:text-center font-bold">
          <Link to="/">
            <img className="w-24 inline-block" src={logoWhite} alt="izarada.com logo"></img>
          </Link>
        </div>
          <div className="mt-3">Kontaktirajte nas: <p>
          <a href="emailto:internet-zarada-web@outlook.com">internet-zarada-web@outlook.com</a>
            </p></div>
          <div className="text-xs">&copy; 2020 Sva prava zadržana
          <br/> izarada.com
          </div>

        </div>
        
      </div>
    
  )
}

export default footer
